import React from "react"; 
import { Route, Switch } from "react-router-dom"; 
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Forms from "./containers/Forms";
import Login from "./containers/Login";
import About from "./containers/About";
import Faq from "./containers/FAQ";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";

export default ({ childProps }) => 
  <Switch>
    <AuthenticatedRoute path="/form" exact component={Forms} props={childProps} />
    <AuthenticatedRoute path="/" exact component={Forms} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />
    <UnauthenticatedRoute path="/" exact component={Login} props={childProps} /> 
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} /> 
    <UnauthenticatedRoute path="/about" exact component={About} props={childProps} /> 
    <UnauthenticatedRoute path="/faq" exact component={Faq} props={childProps} /> 
    <Route component={Login}  props={childProps} /> 
  </Switch>; 