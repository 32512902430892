import React, { Component } from "react"; 
import Card from 'react-bootstrap/Card'

 
export default class About extends Component { 
   
  render() { 
    return ( 
      <div className="contentPage pb-5">
        <Card style={{ width: '80%' }} className="shadow-lg p-3 mb-5 bg-white rounded">
        <Card.Body>
          <React.Fragment>  
            <div className=""> 
                <h2>About</h2>
                <br />
                <p>In Spring 2017, the UCI Libraries was awarded a 3-year IMLS grant in the community anchors category for "<a href="https://ocseaa.lib.uci.edu/imls_grant" target="_blank" rel="noopener noreferrer">Transforming Knowledge/Transforming Libraries" (TKTL)</a>. This three-year research project explored the outcomes of undergraduate students applying what they learned in ethnic studies combined with lived experience in contributing to community archives. The research team (Audra Eagle Yun, Jimmy Zavala, Thuy Vo Dang, and Krystal Tribbett) partnered with the UCI departments of Asian American Studies, Chicano/Latino Studies and African American Studies, as well as stakeholders representing organizations throughout Orange County, California. Additionally, this collaborative partnership worked to connect library and information studies practice with the ethnic studies curriculum. TKTL provided undergraduates with first-hand experience in building and providing access to the archives of under-represented communities.Building meaningful academic-community partnerships with organizations throughout Orange County that foreground marginalized histories was a major component of this grant project. UCI Libraries’ Special Collections & Archives’ is committed to fostering the principles and practices of <a href="https://ocseaa.lib.uci.edu/community_archives" target="_blank" rel="noopener noreferrer">community-centered archives</a>.</p>
                <p>The TKTL research team developed the Community Archives Digital Portal as a means to present community institutions and organizations in Orange County with a platform to contribute their collections to Calisphere, making their collections more accessible to the public and providing them with an online presence if needed. The portal allows community partners to describe their digital collections using language that best reflects their communities, enabling them to have agency through descriptive practices and maintain sovereignty over their materials.</p>
                <p>The Community Archives Digital Portal was created by the University of California, Irvine Libraries Transforming Knowledge, Transforming Libraries research team in partnership with the California Digital Library, and with support from the Institute of Museum and Library Services (IMLS) in 2019. UCI Libraries Programmers Justin Chou and Sean Claudio (with support from Paul Park in Digital Scholarship Services and Director of Information Technology Adrian Petrisor) built this submission portal in collaboration with the <a href="https://cdlib.org/services/psc/" target="_blank" rel="noopener noreferrer">Publishing and Special Collections</a> team of the California Digital Library.</p>
            </div> 
            </React.Fragment>
        </Card.Body>
      </Card>
    </div>
    ); 
  } 
} 