export default { 
    MAX_ATTACHMENT_SIZE: 5000000, 
    cognito: { 
      REGION: "us-east-2", 
      USER_POOL_ID: "us-east-2_j7ppTF9rb", 
      APP_CLIENT_ID: "6501okes42jm9kdda2af571mik", 
      IDENTITY_POOL_ID: "us-east-2:691a8c7f-66db-472c-a9ae-5c295ce2dea1" 
    },
    apiKey: "AIzaSyC0PTVReq6GnQesrv3XiEj2fyiYBSKYoYU",
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
    spreadsheetId: "1DeHpPrBMbSncuHTdWiw7u6HCcDl3P6CDpowBlb1pu94" 
}; 