import React from 'react'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

class Step2 extends React.Component {

    render() {
        const { currentStep, files, sequence, metaData } = this.props

        const renderCopyrightStatusTooltip = props => (
            <div
              {...props}
              style={{
                backgroundColor: 'lightgrey',
                padding: '2px 10px',
                color: 'black',
                borderRadius: 3,
                ...props.style,
              }}
            >
              A coded designation for the copyright status of the resource, at the time the rights statement is recorded.
            </div>
          );

        if (currentStep < 2) return null
        if (sequence + 2 !== currentStep) return null

        return (
            <div className="form-group">
            <h3>Tell us what you have</h3>
            <br />
            <label><span id="redAsterisk">*</span> required field</label>
            <br />
            <label htmlFor="title" className="required-field">Title</label>
            <input
                className="form-control"
                id="title"
                name="title"
                type="text"
                placeholder="Title of item(s)"
                value={metaData[sequence].title}
                required
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />
            <label htmlFor="description">Description</label>
            <input
                className="form-control"
                id="description"
                name="description"
                type="text"
                placeholder="Short caption about your object i.e. Photograph of juggler on stage"
                value={metaData[sequence].description}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />
            <label htmlFor="type" className="required-field">Type</label>
            <Form.Control
                name="type"
                id="type"
                as="select"
                onChange={e => this.props.handleChange(e, sequence)}
                value={metaData[sequence].type}
                required
              >
                <option value="">Select type</option>
                <option value="audio">Audio</option>
                <option value="dataset">Dataset</option>
                <option value="image">Image</option>
                <option value="physical object">Physical Object</option>
                <option value="software">Software</option>
                <option value="text">Text</option>
                <option value="video">Video</option>
            </Form.Control>
            <br />
            <label htmlFor="copyright" className="required-field">Copyright Status</label>
            <OverlayTrigger
                placement="right-start"
                delay={{ show: 250, hide: 400 }}
                overlay={renderCopyrightStatusTooltip}
            ><FontAwesomeIcon icon={faQuestionCircle} color="#007bff"/></OverlayTrigger>
            <Form.Control
                name="copyright"
                id="copyright"
                as="select"
                onChange={e => this.props.handleChange(e, sequence)}
                value={metaData[sequence].copyright}
                required
              >
                <option value="">Select Status</option>
                <option value="copyrighted">Copyrighted</option>
                <option value="public domain">Public domain</option>
                <option value="copyright unknown">Copyright unknown</option>
            </Form.Control>
            <br />
            <label htmlFor="copyrightStatement">Copyright Statement</label>
            <Form.Control 
                as="textarea" 
                rows="3" 
                name="copyrightStatement"
                id="copyrightStatement"
                placeholder={metaData[sequence].copyrightStatementPlaceholder}
                onChange={e => this.props.handleChange(e, sequence)}
                value={metaData[sequence].copyrightStatement}
            />
            {/*
            <input
                className="form-control"
                id="copyright"
                name="copyright"
                type="text"
                placeholder="Name(s) of rights holder(s), if known."
                value={metaData[sequence].copyright}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            */}
            <br />
            <label htmlFor="date">Date</label>
            <input
                className="form-control"
                id="date"
                name="date"
                type="text"
                placeholder="A single date or inclusive dates indicating when the resource was created."
                value={metaData[sequence].date}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />
            <label htmlFor="creator">Creator: Who made or contributed to this item?</label>
            <input
                className="form-control"
                id="creator"
                name="creator"
                type="creator"
                placeholder="Creator(s)"
                value={metaData[sequence].creator}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />
            <label htmlFor="publisher">Publisher: If this is a published work, such as Orange County</label>
            <input
                className="form-control"
                id="publisher"
                name="publisher"
                type="publisher"
                placeholder="Publisher"
                value={metaData[sequence].publisher}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />
            <label htmlFor="language">Language(s) Separated by semicolon</label>
            <input
                className="form-control"
                id="language"
                name="language"
                type="language"
                placeholder="Language(s)"
                value={metaData[sequence].language}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />
            <label htmlFor="keyword">Keyword/tags (separate with semicolon)</label>
            <input
                className="form-control"
                id="keyword"
                name="keyword"
                type="keyword"
                placeholder="Keyword/tags"
                value={metaData[sequence].keyword}
                onChange={e => this.props.handleChange(e, sequence)}
            />
            <br />

            {['radio'].map(type => (
                <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                    inline
                    label="Single item"
                    name="radio"
                    type={type}
                    id={`inline-${type}-1`}
                    checked={files.length === 1 ? 'checked' : ''}
                    onChange={() => {}}
                />
                <Form.Check
                    inline
                    label="Group of items"
                    name="radio"
                    type={type}
                    id={`inline-${type}-2`}
                    checked={files.length > 1 ? 'checked' : ''}
                    onChange= {() => {}}
                />
                </div>
            ))}
            {/*<button type="button" onClick={this.props.postAPI}>Test Post</button>*/}
            </div>
        )
    }
}

export default Step2
