import React, { Component } from "react"; 
import Card from 'react-bootstrap/Card'

 
export default class FAQ extends Component { 
   
  render() { 
    return ( 
      <div className="contentPage pb-5">
        <Card style={{ width: '80%' }} className="shadow-lg p-3 mb-5 bg-white rounded">
          <Card.Body>
            <React.Fragment>  
              <div className=""> 
                  <h1>FAQ</h1>
                  <br />
                  <h4>Why become a contributor?</h4>
                  <p>We can help! There are many benefits to contributing your digital collections to the California Digital Library, including:</p>
                  <ul>
                      <li>Raising the online profile of your institution</li>
                      <li>Making your collections more discoverable through sites optimized for search engines</li>
                      <li>Helping your users see your collections in the context of a larger aggregation</li>
                      <li>Taking advantage of free tools and expertise to build up your digital capacity</li>
                      <li>Expanding your grant opportunities</li>
                      <li>Using templates to describe your collections in ways that make sense to your users</li>
                  </ul>
                  <h4>How can I register?</h4>
                  <p>To become a contributor please email a request to <a href="mailto:ocseaa@uci.edu">ocseaa@uci.edu</a>. </p>
                  <h4>How to contribute your digital material</h4>
                  <p>After registering yourself and your organization, we will send you a login for the Community Archives Digital Portal.  Use the pages of the portal to describe your material(s) and upload your file(s).</p>
                  <p>You will receive an email confirming your submission after you have uploaded your files and completed the descriptive fields. Your submission will be processed and uploaded by UCI Libraries staff, and made available for research in <a href="https://calisphere.org/" target="_blank" rel="noopener noreferrer">Calisphere</a>.</p>
                  <h4>How do I edit or remove an existing record?</h4>
                  <p>Email <a href="mailto:ocseaa@uci.edu">ocseaa@uci.edu</a> with the file/record information and description of changes that need to be made. It may take several weeks for a change to take place; please let us know if there is an urgent need to take down/remove an item.</p>
                  <h4>How long will it take for my submitted files to be published?</h4>
                  <p>Depending on the size and number of files submitted, our team will be able to review and publish submissions every few months (if not more frequently).</p>
                  <h4>What if I want help preserving original materials, records, and/or artifacts?</h4>
                  <p>UCI Libraries Special Collections & Archives serves the campus and Orange County regional community by fostering curiosity in and discovery of physical and digital collections through preservation, access, and instruction. If you or your organization is interested in having a consultation about your archives, starting a documentation/oral history project, or donating physical materials, please contact the Orange County & Southeast Asian Archive Center at <a href="mailto:ocseaa@uci.edu">ocseaa@uci.edu</a>. </p>
                  <h4>Have additional questions about contributing to Calisphere and OAC?</h4>
                  <p>The CDL offers additional resources on the <a href="https://help.oac.cdlib.org/support/home" target="_blank" rel="noopener noreferrer">OAC/Calisphere Contributor Help Center</a>, including user guides and tutorials. See the <a href="https://help.oac.cdlib.org/support/solutions/articles/9000176446-oac-calisphere-community-calendar" target="_blank" rel="noopener noreferrer">Community Calendar</a> for upcoming web-based office hours sessions and other activities. If you have any questions about our services, or would like to report issues or bugs, please feel free to contact us by submitting a ticket through the Help Center.</p>
              </div> 
              </React.Fragment>
          </Card.Body>
        </Card>
      </div>
    ); 
  } 
} 