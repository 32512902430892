import React from 'react'
import Form from 'react-bootstrap/Form'

class Step1 extends React.Component {

    render() {
        if (this.props.currentStep !== 1) {
            return null
          }
        
          return (
            <div className="form-group">
              <h3>Tell us who you are</h3>
              <br/>
              <label><span id="redAsterisk">*</span> required field</label>
              <br/>
              <Form.Label>Organization or Group Affiliation</Form.Label>
              <Form.Control
                name="organization"
                id="organization"
                as="select"
                onChange={this.props.handleChange}
                value={this.props.organization}
              >
                <option>Select Organization</option>
                <option>Heritage Museum of Orange County</option>
                <option>The Cambodian Family</option>
                <option>Student Assistants from Special Collections</option>
                <option>Historic Wintersburg</option>
                <option>Garden Grove Historical Society</option>
                <option>Fullerton College Library</option>
                <option>Orange Public Library & History Center</option>
                <option>LGBTQ Center OC</option>
                <option>Orange County Jewish Historical Society</option>
                <option>Pollak Library, CSUF</option>
                <option>Second Baptist Church</option>
                <option>Orange County Heritage Council</option>
                <option>NCNW Inc.</option>
              </Form.Control>
              <br />
              <label htmlFor="firstname" class="required-field">Submitter First Name</label>
              <input
                className="form-control"
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First name"
                value={this.props.firstName}
                onChange={this.props.handleChange}
                required
              />
              <br />
              <label htmlFor="lastname" class="required-field">Submitter Last Name</label>
              <input
                className="form-control"
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last name"
                value={this.props.lastName}
                onChange={this.props.handleChange}
                required
              />
              <br />
              <label htmlFor="email">Submitter Email</label>
              <input
                className="form-control"
                id="email"
                name="email"
                type="email"
                placeholder="email address"
                value={this.props.email}
                onChange={this.props.handleChange}
              />
              <br />
              <label htmlFor="phonenumber">Submitter Phone Number</label>
              <input
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                placeholder="Submitter Phone Number"
                value={this.props.phoneNumber}
                onChange={this.props.handleChange}
              />
              <br />
              {/* Hiding collection input since these will be handled in the registry
              <label htmlFor="collection">Collection Title</label>
              <input
                className="form-control"
                id="collection"
                name="collection"
                type="text"
                placeholder="i.e. Irvine Global Village Festival 2019"
                value={this.props.collection}
                onChange={this.props.handleChange}
              />
              <br />
              <label htmlFor="aboutcollection">About This Collection</label>
              <input
                className="form-control"
                id="aboutCollection"
                name="aboutCollection"
                type="text"
                placeholder="i.e. Collection of photographs of the 2019 Irvine Global Village festival founded in 1990. This festival celebrates the diversity of the City of Irvine, California."
                value={this.props.aboutCollection}
                onChange={this.props.handleChange}
              />
              */}
            </div>
          )
    }
}

export default Step1
