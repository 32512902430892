import React from 'react'
import Card from 'react-bootstrap/Card'
import Step1 from './Step1'
import Step2 from './Step2'
import $ from 'jquery';
import { Auth } from "aws-amplify"; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import config from '../config'
import { load } from '../helpers/spreadsheet';

//https://css-tricks.com/the-magic-of-react-based-multi-step-forms/

class Forms extends React.Component {
  constructor(props) {
    super(props)
    this.fileInput = React.createRef();
    this.form = React.createRef();
    this.validate = this.validate.bind(this);
    this.state = {
      totalStep: 2,
      currentStep: 1,
      organization: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      collection: '',
      aboutCollection: '',
      metaData: [],
      files: {},
      cardTitle: '',
      formStatus: '',
      apiToken: '',
      apiKey: '',
      contributors: '',
      intervalId: 0
    }
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => this.setState({
      email: user.attributes.email,
      apiToken: user.attributes['custom:token'],
      apiKey: user.attributes['custom:sesKey']
    }))
    .catch(err => console.log(err));
    this.baseState = this.state;
  }

  initClient = () => {
    console.log('init');

    // 2. Initialize the JavaScript client library.
    window.gapi.client
      .init({
        apiKey: config.apiKey,
        // Your API key will be automatically added to the Discovery Document URLs.
        discoveryDocs: config.discoveryDocs
      })
      .then(() => {
        console.log('load');
      // 3. Initialize and make the API request.
      load(this.onLoad);
    });
  };

  componentDidMount() {
    // 1. Load the JavaScript client library.
    console.log('component mount');

    window.gapi.load("client", this.initClient);
   this.form.current.scrollTo(0, 0);
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  
  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  handleChange = (event, seq = -1) => {
    const { name, value } = event.target;
    if (seq === -1) {
      this.setState({
        [name]: value
      })
    } else {
      let { metaData } = this.state;

      metaData[seq] = {
        ...metaData[seq],
        [name]: value
      }

      if(name === "copyright"){
        console.log('copyright value '+ value);
        switch(value) {
          case 'copyrighted':
            metaData[seq] = {
              ...metaData[seq],
              copyrightStatementPlaceholder: 'e.g. Transmission or reproduction of materials protected by copyright beyond that allowed by fair use requires the written permission of the copyright owners. Works not in the public domain cannot be commercially exploited without permission of the copyright owner. Responsibility for any use rests exclusively with the user'
            }
            break;
          case 'public domain':
            metaData[seq] = {
              ...metaData[seq],
              copyrightStatementPlaceholder: 'e.g. Material in the public domain. No restrictions on use.'
            }
            break;
          case 'copyright unknown':
            metaData[seq] = {
              ...metaData[seq],
              copyrightStatementPlaceholder: 'e.g. Some materials in these collections may be protected by the U.S. Copyright Law (Title 17, U.S.C.). In addition, the reproduction, and/or commercial use, of some materials may be restricted by gift or purchase agreements, donor restrictions, privacy and publicity rights, licensing agreement(s), and/or trademark rights. Distribution or reproduction of materials protected by copyright beyond that allowed by fair use requires the written permission of the copyright owners. To the extent other restrictions apply, permission for distribution or reproduction from the applicable rights holder is also required. Responsibility for obtaining permissions, and for any use rests exclusively with the user.'
            }
            break;
          default:
            break;
        }
      }

      this.setState({
        metaData
      })
    }
  }

  handleFiles = event => {
    this.setState({
      files: event.target.files
    })
  }

  validate() {
    return this.form.current.reportValidity();
  }

  
  postAPI = event => {
    event.preventDefault()
    //set local state constants
    const { organization, firstName, lastName, email, phoneNumber, collection, metaData, files } = this.state;
    //create object from form data
    var formObject = {org: organization, fname: firstName, lname: lastName, mail: email, phone: phoneNumber, col: collection, data: metaData, file: files};

    $.ajax({
      type: "POST",
      url : "https://e02sgkfiyi.execute-api.us-west-2.amazonaws.com/dev/tktl",
      dataType: "json",
      crossDomain: true,
      contentType: "application/json",
      data: JSON.stringify(formObject),
      success: function () {
        // clear form and show a success message
        alert("Successfull");
      },
      error: function (jqXHR, textStatus, errorThrown) {
        // show an error message
        console.log('jqXHR:');
                console.log(jqXHR);
                console.log('textStatus:');
                console.log(textStatus);
                console.log('errorThrown:');
                console.log(errorThrown);
        alert("UnSuccessfull");
      }});
  }
  
  handleSubmit = event => {
    event.preventDefault()
    //set local state constants
    const { currentStep, files, metaData, apiToken, apiKey } = this.state;
    const seq = currentStep - 2;

    if (seq < 0) return;

    //initiate nuxeo connection
    var Nuxeo = require('nuxeo/es5')
    var nuxeo = new Nuxeo({
      baseURL: 'https://nuxeo.cdlib.org/Nuxeo/',
      auth: {
        method: 'token',
        token: apiToken
      },
    })

    nuxeo
      .connect()
      .then(function(client) {
        console.log('Connected OK!')
      })
      .catch(function(error) {
        console.log('Error authenticating')
        throw error
      })

    // 1st STEP Metadata fields
    const { collection } = this.state;
    let metaCollection = `\nucldc_schema:collection= ${collection}`;
    const { organization } = this.state;
    let metaOrganization = `\nucldc_schema:campusunit= ${organization}`;
    //map first, last name, phone to contributor
    const { firstName, lastName, phoneNumber, email } = this.state;
    var metaContributor = "";
    if(firstName !== "" || lastName !== "" || phoneNumber !== "" || email !== ""){
      metaContributor = `\nucldc_schema:contributor=[ {"authorityid":"","name": "${firstName}, ${lastName} - ${phoneNumber} - ${email}","nametype":"persname","role":"", "source":""} ]`;
    }
    // let metaaboutcollection = `\ndc:description = ${aboutcollection}`;

    for (let i = 0; i < files.length; i += 1) {
      let input = files[i];            
      // Metadata depends on currentStep. First Step 2 - CurrentStep : 2
      // 2nd STEP Metadata fields, using array.
      const metaTitle = '\ndc:title= ' + metaData[i].title;
      const metaDescription = '\ndc:description = ' + metaData[i].description;
      const metaType = '\nucldc_schema:type= ' + metaData[i].type;
      const metaCopyright = '\nucldc_schema:rightsstatus= ' + metaData[i].copyright;
      const metaCopyrightStatement = '\nucldc_schema:rightsstatement= ' + metaData[i].copyrightStatement;
      const metaDate = "\nucldc_schema:date= [ {\"date\":\"" + metaData[i].date + "\"} ]";
      const metaPublisher = '\nucldc_schema:publisher= ' + metaData[i].publisher;
      const metaLanguage = "\nucldc_schema:language=[ {\"language\":\"" + metaData[i].language + "\"} ]";

      // var complexprop = 'ucldc_schema:formgenre=[ {"heading":"sculpture (visual work)","source":"aat"}, {"heading":"Heian","source":"aat"} ]'
      //map keywords to subject name
      const keywords = metaData[i].keyword.split(';');
      var keywordsString = "";
      for(var j = 0; j < keywords.length; j++) {
        keywordsString = keywordsString + "{\"authorityid\":\"\",\"name\":\"" + keywords[j] + "\",\"nametype\":\"\",\"role\":\"\",\"source\":\"\"},";
      }
      const metaKeywords = "\nucldc_schema:subjectname=["+ keywordsString.replace(/,$/g, "") +"]";
      const file = new Nuxeo.Blob({
        content: input
      })
      

      //code to switch folder based on organization
      let upload_folder = "";
      if(organization === 'Heritage Museum of Orange County'){
        upload_folder = '/asset-library/UCI/tktltest/Heritage Museum'; 
      } else if(organization === 'The Cambodian Family'){
        upload_folder = '/asset-library/UCI/tktltest/The Cambodian Family'; 
      } else if(organization === 'Student Assistants from Special Collections'){
        upload_folder = '/asset-library/UCI/tktltest/SCA_Students'; 
      } else if(organization === 'Historic Wintersburg'){
        upload_folder = '/asset-library/UCI/tktltest/Historic Wintersburg'; 
      } else if(organization === 'Garden Grove Historical Society'){
        upload_folder = '/asset-library/UCI/tktltest/Garden Grove Historical Society'; 
      } else if(organization === 'Fullerton College Library'){
        upload_folder = '/asset-library/UCI/tktltest/Fullerton College Library';
      } else if(organization === 'Orange Public Library & History Center'){
        upload_folder = '/asset-library/UCI/tktltest/Orange Public Library & History Center'; 
      } else if(organization === 'LGBTQ Center OC'){
        upload_folder = '/asset-library/UCI/tktltest/LGBTQ Center OC'; 
      } else if(organization === 'Orange County Jewish Historical Society'){
        upload_folder = '/asset-library/UCI/tktltest/Orange County Jewish Historical Society'; 
      } else if(organization === 'Pollak Library, CSUF'){
        upload_folder = '/asset-library/UCI/tktltest/Pollak Library, CSUF';
      } else if(organization === 'Second Baptist Church'){
        upload_folder = '/asset-library/UCI/tktltest/Second Baptist Church'; 
      } else if(organization === 'Orange County Heritage Council'){
        upload_folder = '/asset-library/UCI/tktltest/Orange County Heritage Council'; 
      } else if(organization === 'NCNW Inc.'){
        upload_folder = '/asset-library/UCI/tktltest/NCNW Inc.'; 
      } else {
        upload_folder = '/asset-library/UCI/tktltest/';
      }

      nuxeo
        .batchUpload()
        .upload(file)
        .then(function(res) {
          nuxeo
            .operation('FileManager.ImportWithMetaData')
            .context({
              currentDocument: upload_folder,
              docMetaData: metaOrganization + metaContributor + metaCollection + metaTitle + metaDescription + metaType + metaCopyright + metaCopyrightStatement + metaDate + metaPublisher + metaLanguage + metaKeywords
              // complexprop + title + description + creator + copyright + language
            })
            .input(res.blob)
            .execute({
              schemas: ['file'],
              path: file.name
            })

          //http://explorer.nuxeo.com/nuxeo/site/distribution/Nuxeo%20Platform%2010.3/viewOperation/JsonStack.ToggleDisplay
          //https://doc.nuxeo.com/nxdoc/error-handling/
          console.log(nuxeo.operation('JsonStack.ToggleDisplay'))
          console.log(input)
        }).then(function() {
          //create object from form data
          var formObject = {org: organization, fname: firstName, lname: lastName, mail: email, phone: phoneNumber, col: collection, data: metaData[i], fileName: file.name};

          $.ajax({
            type: "POST",
            url : "https://e02sgkfiyi.execute-api.us-west-2.amazonaws.com/dev/tktl",
            dataType: "json",
            crossDomain: true,
            contentType: "application/json",
            headers: {"X-Api-Key": apiKey},
            data: JSON.stringify(formObject),
            success: function () {
              // clear form and show a success message
            },
            error: function (jqXHR, textStatus, errorThrown) {
              // show an error message
              console.log('jqXHR:');
                      console.log(jqXHR);
                      console.log('textStatus:');
                      console.log(textStatus);
                      console.log('errorThrown:');
                      console.log(errorThrown);
              alert("UnSuccessfull");
            }
          });
        }).then(
            this.setState({
              cardTitle: 'Form submitted successfully',
              formStatus: 'success'
            })
        )
    }
  }

  _next = () => {
    const { totalStep, currentStep, files } = this.state;
    if(!this.validate()){
      return;
    }
    if (currentStep === 1 && Object.keys(files).length === 0) {
      alert('You have to select files at least 1.')
      return;
    }

    if (currentStep === 1) {
      const metaData = {
        title: '',
        description: '',
        copyright: '',
        keyword: '',
        date: '',
        creator: '',
        language: '',
      }

      const metaDataArray = []
      for (let i = 0; i < files.length; i++) {
        metaDataArray.push(metaData)
      }

      this.setState({
        totalStep: files.length + 1,
        metaData: metaDataArray
      })
    }
    this.scrollToTop();

    this.setState({
      currentStep: currentStep > totalStep ? totalStep : currentStep + 1
    })
  }

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1

    this.setState({
      currentStep
    })
  }

  previousButton() {
    let currentStep = this.state.currentStep

    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this._prev}
        >
          Previous
        </button>
      )
    }
    return null
  }

  nextButton() {
    const { totalStep, currentStep } = this.state;

    if (currentStep < totalStep) {
      return (
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      )
    }

    return null
  }

  render() {
    const { currentStep, files, metaData, formStatus } = this.state

    const renderCopyrightStatusTooltip = props => (
      <div
        {...props}
        style={{
          backgroundColor: 'lightgrey',
          padding: '2px 10px',
          color: 'black',
          borderRadius: 3,
          ...props.style,
        }}
      >
        <a href="https://doc.nuxeo.com/nxdoc/supported-file-formats/" target="_blank" rel="noopener noreferrer">Supported file types</a>
      </div>
    );

    return (
      <div className="contentPage pb-5">
      <Card bg={this.state.formStatus} style={{ width: '80%' }}>
        <Card.Body>
        {formStatus !== 'success'
          ? 
          <React.Fragment>
            <span>Step {currentStep}</span>
            {currentStep >= 2 && (
              <p>
                <strong>
                  ({currentStep} - {files[currentStep - 2].name})
                </strong>
              </p>
            )}
            <form onSubmit={this.handleSubmit} ref={this.form}>
            <Step1
              currentStep={currentStep}
              handleChange={this.handleChange}
              organization={this.state.organization}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              email={this.state.email}
              phoneNumber={this.state.phoneNumber}
              collection={this.state.collection}
              aboutCollection={this.state.aboutCollection}
            />
            {[...Array(files.length)].map((e, index) => (
              <Step2
                key={index}
                currentStep={currentStep}
                metaData={metaData}
                sequence={index}
                files={files}
                handleChange={this.handleChange}
                postAPI={this.postAPI}
              />
            ))}
              <label>
                Click to select file(s) (Max total file size 2GB) <OverlayTrigger
                placement="right-start"
                delay={{ show: 250, hide: 1600 }}
                overlay={renderCopyrightStatusTooltip}
              ><FontAwesomeIcon icon={faQuestionCircle} color="#007bff"/></OverlayTrigger>
              </label>
              <input
                className="form-control-file"
                type="file"
                id="input"
                multiple
                onChange={this.handleFiles}
              />
              <br />
              {this.previousButton()}
              {this.nextButton()}
              <br />
              <div
                className="row"  
              >
              <div
                  className="col-md-4"  
                ></div>
                <div
                  className="col-md-4 text-center"  
                >
                {currentStep >= 2 && <button type="submit" class="btn btn-primary">Submit</button>}
                </div>
                <div
                  className="col-md-4"  
                ></div>
              </div>
            </form>
          </React.Fragment>
          : <Card.Title>{this.state.cardTitle}</Card.Title>
          }
        </Card.Body>
      </Card>
      </div>
    )
  }
}


export default Forms
