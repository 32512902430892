import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react"
import ReactDOM from "react-dom"
import App from './App'; 
import { BrowserRouter as Router } from"react-router-dom";
import Amplify from "aws-amplify";
import config from "./config";
// import Input from "./Input"
import './style.css'

Amplify.configure({ 
  Auth: { 
    mandatorySignIn: true, 
    region: config.cognito.REGION, 
    userPoolId: config.cognito.USER_POOL_ID, 
    identityPoolId: config.cognito.IDENTITY_POOL_ID, 
    userPoolWebClientId: config.cognito.APP_CLIENT_ID 
  }
}); 
// JSX
ReactDOM.render(
  <Router>
    <App />
  </Router>, document.getElementById("root")
);