import React, { Component } from "react"; 
import { FormGroup, FormControl, FormLabel } from "react-bootstrap"; 
import LoaderButton from "./LoaderButton"; 
import "./Login.css"; 
import { Auth } from "aws-amplify"; 
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
 
export default class Login extends Component { 
  constructor(props) { 
    super(props); 
 
    this.state = { 
      email: "", 
      isLoading: false, 
      password: "" 
    }; 
  } 
 
  validateForm() { 
    return this.state.email.length > 0 && this.state.password.length > 0; 
  } 
 
  handleChange = event => { 
    this.setState({ 
      [event.target.id]: event.target.value 
    }); 
  } 
 
  handleSubmit = async event => { 
    event.preventDefault(); 
   
    this.setState({ isLoading: true }); 
   
    try { 
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.push("/form");
    } catch (e) { 
      alert(e.message); 
      this.setState({ isLoading: false }); 
    } 
  } 
   
   
   
 
  render() { 
    return ( 
      <div className="Login"> 
        <Jumbotron fluid >
          <Container className="pt-5">
            <h2 className="display-5"><b>Who can contribute?</b></h2>
            <p>The Community Archives Digital Portal provides institutions and organizations with a free and simple tool to contribute digital content to Calisphere, the <a href="https://cdlib.org/" target="_blank" rel="noopener noreferrer">California Digital Library's (CDL)</a> gateway to California’s cultural heritage.</p>        
            <p><a href="https://calisphere.org/" target="_blank" rel="noopener noreferrer">Calisphere</a> provides free access to unique and historically important artifacts for research, teaching, and curious exploration. The Community Archives Digital Portal, provides a way for you to contribute digital content to Calisphere with technical support from University of California, Irvine Libraries (UCI Libraries) Special Collections and Archives.</p>
          </Container>            
        </Jumbotron>
        <form onSubmit={this.handleSubmit} className="shadow-lg p-3 mb-5 rounded"> 
          <FormGroup controlId="email" size="large"> 
            <FormLabel>Email</FormLabel> 
            <FormControl 
              autoFocus 
              type="email" 
              value={this.state.email} 
              onChange={this.handleChange} 
            /> 
          </FormGroup> 
          <FormGroup controlId="password" size="large"> 
            <FormLabel>Password</FormLabel> 
            <FormControl 
              value={this.state.password} 
              onChange={this.handleChange} 
              type="password" 
            /> 
          </FormGroup> 
          <LoaderButton 
              block 
              size="large" 
              disabled={!this.validateForm()} 
              type="submit" 
              isLoading={this.state.isLoading} 
              text="Login" 
              loadingText="Logging in…" 
          /> 
          <br/>
          <div className="text-center">
            <p><small>Don't have an account? Contact <a href="mailto:ocseaa@uci.edu">ocseaa@uci.edu</a> to request an account.</small></p>
            <p><small>For more information visit this <a href="https://ocseaa.lib.uci.edu/#partnerSteps" target="_blank" rel="noopener noreferrer">page</a> for steps to becoming a partner.</small></p>
          </div>
        </form> 
      </div> 
    ); 
  } 
} 