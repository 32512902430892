import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Card from 'react-bootstrap/Card'
import LoaderButton from "./LoaderButton";
import "./Settings.css";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <div className="contentPage pb-5">
      <Card style={{ width: '80%' }}>
        <Card.Body>
          <div className="Settings">
            <LinkContainer to="/settings/password">
              <LoaderButton
                block
                bsSize="large"
                text="Change Password"
              />
            </LinkContainer>
          </div>
        </Card.Body>
      </Card>
      </div>
    );
  }
}