import React, { Component } from 'react';
import Routes from "./Routes";
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import ocseaa from './OC_SEAA_logo.jpg'
import imls from './imls.png'


class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }
  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }
  

  render() {
    const navbar = {backgroundColor: '#0064A4'};
    const childProps = { 
      isAuthenticated: this.state.isAuthenticated, 
      userHasAuthenticated: this.userHasAuthenticated 
    }; 

    return (
      !this.state.isAuthenticating &&
      <div className=""> 
      <Navbar style={navbar} expand="lg">
      <Navbar.Brand href="/form">Community Archives Digital Portal</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
          {this.state.isAuthenticated
            ? <Nav className="ml-auto"><Nav.Link href="/form">Form</Nav.Link><Nav.Link href="/settings">Settings</Nav.Link><Nav.Link onClick={this.handleLogout}>Logout</Nav.Link></Nav>
            : <Nav className="ml-auto"><Nav.Link href="/login">Login</Nav.Link><Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/faq">FAQ</Nav.Link></Nav>
          }
      </Navbar.Collapse>
      </Navbar>
      <Routes childProps={childProps} />
      <section className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="footer text-center rounded bg-white">
                Developed by <a href="https://ocseaa.lib.uci.edu/"><Image src={ocseaa} rounded /></a> with support from <Image src={imls} rounded />
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}
  
export default withRouter(App);